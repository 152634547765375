import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface IFiscalYearDropdown {
  selectedFiscalYear: string | null;
  fiscalList: string[];
  onSelectFiscalYear: (_fiscalYear: string | null) => void;
}

const FiscalYearDropdown = ({ selectedFiscalYear, fiscalList, onSelectFiscalYear }: IFiscalYearDropdown) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown isOpen={open} toggle={() => setOpen((prev) => !prev)} className="mr-2">
      <DropdownToggle caret color="primary">
        {selectedFiscalYear ?? 'Select a Fiscal Year'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onSelectFiscalYear(null)}>---</DropdownItem>
        {fiscalList.map((item) => (
          <DropdownItem key={item} onClick={() => onSelectFiscalYear(item)}>
            {item}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FiscalYearDropdown;
