import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface IAdvanceSearchDropdown {
  isAdvanceSearch: boolean;
  setIsAdvanceSearch: (_isAdvanceSearch: boolean) => void;
}

const AdvanceSearchDropdown = ({ isAdvanceSearch, setIsAdvanceSearch }: IAdvanceSearchDropdown) => {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown isOpen={open} toggle={() => setOpen((prev) => !prev)} className="mr-2">
      <DropdownToggle caret color="primary">
        {!isAdvanceSearch ? 'Basic Search' : 'Advanced Search'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setIsAdvanceSearch(false)}>Basic Search</DropdownItem>
        <DropdownItem onClick={() => setIsAdvanceSearch(true)}>Advanced Search</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AdvanceSearchDropdown;
