import React, { ChangeEventHandler } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';

interface IGridTextField {
  gridSize: number;
  handleChange: ChangeEventHandler;
  inputId: string;
  inputLabel: string;
  value: string;
  height?: string;
}

export const GridTextField = ({ gridSize, inputLabel, inputId, handleChange, value, height = 'auto' }: IGridTextField) => {
  return (
    <Col xs={gridSize}>
      <FormGroup>
        <Label>{inputLabel}</Label>
        <Input style={{ height: height }} id={inputId} label={inputLabel} type="textarea" onChange={handleChange} value={value} margin="normal" />
      </FormGroup>
    </Col>
  );
};
