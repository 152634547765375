import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label } from 'reactstrap';

interface IFiscalYearDropdown {
  label: string;
  id: string;
  value: string | null;
  options: string[];
  onSelect: (_options: string | null) => void;
}

const InputDropdown = ({ id, label, value, options, onSelect }: IFiscalYearDropdown) => {
  const [open, setOpen] = useState(false);

  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <Dropdown isOpen={open} toggle={() => setOpen((prev) => !prev)}>
        <DropdownToggle
          caret
          color="muted"
          style={{
            alignItems: 'center',
            border: '1px solid #ced4da',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          {value ?? '-'}
        </DropdownToggle>
        <DropdownMenu style={{ overflowY: 'auto', maxHeight: 400 }}>
          <DropdownItem onClick={() => onSelect(null)}>---</DropdownItem>
          {options.map((option) => (
            <DropdownItem key={option} onClick={() => onSelect(option)}>
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </FormGroup>
  );
};

export default InputDropdown;
