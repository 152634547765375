import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import InputDropdown from './InputDropdown';

export interface IMpaFormValues {
  mpa?: string;
  lob?: string;
  product?: string;
  status?: string;
  scheduled_start_from?: Date;
  scheduled_start_to?: Date;
  scheduled_end_from?: Date;
  scheduled_end_to?: Date;
  delta_from?: number;
  delta_to?: number;
  total_from?: number;
  total_to?: number;
}

const colProps = {
  xs: 4,
};

const parallelColProps = {
  xs: 2,
};

interface IMpaSearchForm {
  onSubmit: (_formValues: IMpaFormValues) => void;
  mpas: any[];
}

const MpaSearchForm = ({ onSubmit, mpas }: IMpaSearchForm): JSX.Element => {
  const [formValues, setFormValues] = useState<IMpaFormValues>({});

  const dropdown = useMemo(
    () => ({
      lobs: Object.keys(mpas.reduce((total, current) => ({ ...total, [current.client_business_unit]: true }), {})).sort(),
      status: Object.keys(mpas.reduce((total, current) => ({ ...total, [current.mpa_status]: true }), {})).sort(),
    }),
    [mpas]
  );

  const products = useMemo(
    () => Object.keys(mpas.filter((mpa) => mpa.client_business_unit === formValues.lob || !formValues.lob).reduce((total, current) => ({ ...total, [current.products]: true }), {})).sort(),
    [mpas, formValues.lob]
  );

  const handleFormChange = (key: string, value?: string | null | Date | number) => {
    setFormValues((prev) => {
      const finalFormValues = { ...prev, [key]: value };
      if (key === 'lob' && finalFormValues[key] !== prev[key]) finalFormValues['product'] = null;
      return finalFormValues;
    });
  };

  const handleSubmit = useCallback(() => {
    onSubmit(formValues);
  }, [formValues]);

  const handleReset = () => {
    setFormValues({});
    onSubmit(null);
  };

  return (
    <div style={{ border: '1px solid #dee2e6', padding: '10px' }}>
      <Row style={{ alignItems: 'flex-end' }}>
        <Col {...colProps}>
          <FormGroup>
            <Label for="mpa">MPA</Label>
            <Input id="mpa" name="mpa" value={formValues['mpa'] ?? ''} onChange={(e) => handleFormChange('mpa', e.currentTarget.value)} />
          </FormGroup>
        </Col>
        <Col {...colProps}>
          <InputDropdown label="LOB" id="lob" options={dropdown.lobs} value={formValues['lob']} onSelect={(value) => handleFormChange('lob', value)} />
        </Col>
        <Col {...colProps}>
          <InputDropdown label="Product" id="product" options={products} value={formValues['product']} onSelect={(value) => handleFormChange('product', value)} />
        </Col>
        <Col {...colProps}>
          <InputDropdown label="Status" id="status" options={dropdown.status} value={formValues['status']} onSelect={(value) => handleFormChange('status', value)} />
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <Label for="scheduled_start_from">Scheduled Start</Label>
            <DatePicker
              id="scheduled_start_from"
              placeholderText="From"
              selected={formValues['scheduled_start_from']}
              onChange={(date) => handleFormChange('scheduled_start_from', date)}
              dateFormat={'MM/dd/yyyy'}
            />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <DatePicker
              id="scheduled_start_to"
              placeholderText="To"
              selected={formValues['scheduled_start_to']}
              onChange={(date) => handleFormChange('scheduled_start_to', date)}
              dateFormat={'MM/dd/yyyy'}
            />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <Label for="scheduled_end_from">Scheduled End</Label>
            <DatePicker
              id="scheduled_end_from"
              placeholderText="From"
              selected={formValues['scheduled_end_from']}
              onChange={(date) => handleFormChange('scheduled_end_from', date)}
              dateFormat={'MM/dd/yyyy'}
            />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <DatePicker
              id="scheduled_end_to"
              placeholderText="To"
              selected={formValues['scheduled_end_to']}
              onChange={(date) => handleFormChange('scheduled_end_to', date)}
              dateFormat={'MM/dd/yyyy'}
            />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <Label for="delta_from">Delta</Label>
            <Input type="number" id="delta_from" placeholder="From" name="delta_from" value={formValues['delta_from'] ?? ''} onChange={(e) => handleFormChange('delta_from', e.currentTarget.value)} />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <Input type="number" placeholder="To" id="delta_to" name="delta_to" value={formValues['delta_to'] ?? ''} onChange={(e) => handleFormChange('delta_to', e.currentTarget.value)} />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <Label for="total_from">Total</Label>
            <Input type="number" placeholder="From" id="total_from" name="total_from" value={formValues['total_from'] ?? ''} onChange={(e) => handleFormChange('total_from', e.currentTarget.value)} />
          </FormGroup>
        </Col>
        <Col {...parallelColProps}>
          <FormGroup>
            <Input type="number" placeholder="To" id="total_to" name="total_to" value={formValues['total_to'] ?? ''} onChange={(e) => handleFormChange('total_to', e.currentTarget.value)} />
          </FormGroup>
        </Col>
      </Row>
      <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
        <Button style={{ marginRight: '10px' }} onClick={handleReset}>
          Reset
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default MpaSearchForm;
