import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMpaState } from '../../../State/Mpa/Selectors/MpaSelectors';
import { getMpas } from '../../../State/Mpa/Actions/MpaActions';
import MpaDashboard from './MpaDashboard';
import { AppPage, PageHeader, WithLoading } from '../../../Common';

const MpaDashboardWithLoading = WithLoading(MpaDashboard);

const MpaDashboardContainer = () => {
  const mpaState = useSelector(getMpaState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMpas());
  }, [dispatch, getMpas]);

  return (
    <>
      <PageHeader>MPAs</PageHeader>
      <AppPage>
        <MpaDashboardWithLoading loadable={mpaState.getMpaListLoadable} mpaList={mpaState.entities} />
      </AppPage>
    </>
  );
};

export default MpaDashboardContainer;
