import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

interface ISortButton {
  isCurrent: boolean;
  isAsc: boolean | null;
}

const SortButton = ({ isCurrent, isAsc }: ISortButton) => {
  if (!isCurrent) return <UnfoldMoreIcon fontSize="small" color="primary" />;

  if (isAsc) {
    return <KeyboardArrowUpIcon fontSize="small" color="primary" />;
  } else {
    return <KeyboardArrowDownIcon fontSize="small" color="primary" />;
  }
};
export default SortButton;
