import React, { useCallback, useEffect, useMemo, useState } from 'react';
import history from '../../../Utils/History';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'reactstrap';

import { DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination } from '../../../Common';

import { formatNumberToCanadianDollars } from '../../../Utils/FormatNumberToCurrency';
import MpaToolbar from './MpaToolbar';
import MpaSearchForm, { IMpaFormValues as IMpaSearchFormValues } from './MpaSearchForm';
import FiscalYearDropdown from './FiscalYearDropdown';
import SortButton from './SortButton';
import AdvanceSearchDropdown from './AdvanceSearchDropdown';

interface IMpaDashboard {
  mpaList: any[];
}

const format_date = (selectedDate) => {
  let datestring = new Date(selectedDate);
  let isodatestring = datestring.toISOString();
  let fullDate = isodatestring.split('T')[0];
  let dateparts = fullDate.split('-');
  let month = dateparts[1];
  let day = dateparts[2];
  let year = dateparts[0];
  let formattedDate = month + '/' + day + '/' + year;
  return formattedDate;
};

const MpaDashboard = ({ mpaList }: IMpaDashboard) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState({
    totalCount: 0,
    displayData: [],
  });
  const [sortKey, setSortKey] = useState({
    sortKey: null,
    isAsc: null,
  });
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  // load current fiscal year's data on page load
  const currentFiscalYear = new Date().getFullYear().toString();
  const nextFiscalYear = (new Date().getFullYear() + 1).toString();
  const [selectedFiscal, setSelectedFiscal] = useState<string>(`FY-${new Date().getMonth() + 1 >= 11 ? +nextFiscalYear : currentFiscalYear}`);
  const [searchFormValues, setSearchFormValues] = useState<IMpaSearchFormValues>(null);
  // change date format from iso format to m/d/Y to display on manage MPA page

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    setPage(0);
  };

  const onSelectFiscalYear = (fiscalYear: string) => {
    setPage(0);
    setSelectedFiscal(fiscalYear);
  };

  const fiscalYearList = useMemo(() => mpaList.map((item) => item.fiscal).filter((value, index, self) => self.indexOf(value) === index), [mpaList]);

  const onSort = useCallback(
    (selectedSortKey: string) => {
      if (!sortKey.sortKey || selectedSortKey !== sortKey.sortKey) {
        setSortKey({ sortKey: selectedSortKey, isAsc: true });
      } else if (selectedSortKey === sortKey.sortKey && sortKey.isAsc) {
        setSortKey({ sortKey: selectedSortKey, isAsc: false });
      } else {
        setSortKey({ sortKey: null, isAsc: true });
      }
      setPage(0);
    },
    [sortKey]
  );
  const OnSearch = (formValues: IMpaSearchFormValues) => {
    setPage(0);
    setSearchFormValues(formValues);
  };

  const onSelectIsAdvanceSearch = (isAdvanceSearch) => {
    setSearchFormValues(null);
    setSearchValue('');
    setIsAdvanceSearch(isAdvanceSearch);
  };

  useEffect(() => {
    const formatComparedDate = (date) => (date ? new Date(format_date(date)).setHours(0, 0, 0, 0) : null);
    let mpas = [...mpaList];

    if (!isAdvanceSearch && searchValue) {
      const searchValues = searchValue.split(' ');
      searchValues.forEach((element) => {
        // call filter function
        mpas = mpas.filter(
          (data: any) =>
            data.legacy_mpa.toLowerCase().includes(element.toLocaleLowerCase()) ||
            data.mpa_number.toLowerCase().includes(element.toLocaleLowerCase()) ||
            data.client_business_unit.toLowerCase().includes(element.toLocaleLowerCase()) ||
            data.products.toLowerCase().includes(element.toLocaleLowerCase()) ||
            (data.anchor_cid && data.anchor_cid.toLowerCase().includes(element.toLocaleLowerCase()))
        );
      });
    } else if (isAdvanceSearch && searchFormValues) {
      mpas = mpas.filter((data: any) => {
        return (
          (!searchFormValues['mpa'] || `${data.legacy_mpa} ${data.revision_number}`.toLowerCase().includes(searchFormValues['mpa'])) &&
          (!searchFormValues['lob'] || data.client_business_unit === searchFormValues['lob']) &&
          (!searchFormValues['product'] || data.products === searchFormValues['product']) &&
          (!searchFormValues['status'] || data.mpa_status === searchFormValues['status']) &&
          (!searchFormValues['scheduled_start_from'] || formatComparedDate(data.scheduled_start) >= formatComparedDate(searchFormValues['scheduled_start_from'])) &&
          (!searchFormValues['scheduled_start_to'] || formatComparedDate(data.scheduled_start) <= formatComparedDate(searchFormValues['scheduled_start_to'])) &&
          (!searchFormValues['scheduled_end_from'] || formatComparedDate(data.scheduled_end) >= formatComparedDate(searchFormValues['scheduled_end_from'])) &&
          (!searchFormValues['scheduled_end_to'] || formatComparedDate(data.scheduled_end) <= formatComparedDate(searchFormValues['scheduled_end_to'])) &&
          (!searchFormValues['delta_from'] || data.delta >= searchFormValues['delta_from']) &&
          (!searchFormValues['delta_to'] || data.delta <= searchFormValues['delta_to']) &&
          (!searchFormValues['total_from'] || data.grand_total >= searchFormValues['total_from']) &&
          (!searchFormValues['total_to'] || data.grand_total <= searchFormValues['total_to'])
        );
      });
    }

    if (!!selectedFiscal) {
      mpas = mpas.filter((data: any) => data.fiscal && data.fiscal.includes(selectedFiscal));
    }

    if (!!sortKey.sortKey && sortKey.isAsc !== null) {
      mpas.sort((firstMpa, secondMpa) => {
        if (['delta', 'grand_total', 'scheduled_start', 'scheduled_end'].includes(sortKey.sortKey)) {
          if (sortKey.isAsc) return firstMpa[sortKey.sortKey] - secondMpa[sortKey.sortKey];
          return secondMpa[sortKey.sortKey] - firstMpa[sortKey.sortKey];
        } else {
          if (firstMpa[sortKey.sortKey].toUpperCase() < secondMpa[sortKey.sortKey].toUpperCase()) return sortKey.isAsc ? -1 : 1;
          if (firstMpa[sortKey.sortKey].toUpperCase() > secondMpa[sortKey.sortKey].toUpperCase()) return sortKey.isAsc ? 1 : -1;
          return 0;
        }
      });
    }

    setTableData({
      totalCount: mpas.length,
      displayData: [...mpas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)],
    });
  }, [mpaList, selectedFiscal, searchValue, sortKey, page, rowsPerPage, searchFormValues, isAdvanceSearch]);

  return (
    <>
      <div style={{ display: 'flex', marginTop: '2rem' }}>
        <FiscalYearDropdown fiscalList={fiscalYearList} onSelectFiscalYear={onSelectFiscalYear} selectedFiscalYear={selectedFiscal} />
        <AdvanceSearchDropdown isAdvanceSearch={isAdvanceSearch} setIsAdvanceSearch={onSelectIsAdvanceSearch} />

        <Alert color="primary" style={{ padding: '0.375rem 1.25rem', marginBottom: '0' }}>
          The balances in the channel are updated every month on the{' '}
          <strong>
            10<sup>th</sup>
          </strong>{' '}
          automatically. Please verify and adjust the MPA budgets as needed.
        </Alert>
      </div>
      <br />
      {!isAdvanceSearch ? (
        <SearchBar placeholder={'Search for LOB, Product, MPA# or CID'} searchInput={searchValue} handleSearchValue={handleSearchValue} />
      ) : (
        <MpaSearchForm mpas={mpaList} onSubmit={OnSearch} />
      )}
      <MpaToolbar />
      <DashboardTable>
        <thead>
          <tr>
            <th align="left" onClick={() => onSort('legacy_mpa')} style={{ cursor: 'pointer', width: '7%' }}>
              <b>MPA</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'legacy_mpa'} />
            </th>
            <th align="left" onClick={() => onSort('fiscal')} style={{ cursor: 'pointer', width: '10%' }}>
              <b>Fiscal</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'fiscal'} />
            </th>
            <th align="left" onClick={() => onSort('client_business_unit')} style={{ cursor: 'pointer', width: '10%' }}>
              <b>LOB</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'client_business_unit'} />
            </th>
            <th align="left" onClick={() => onSort('products')} style={{ cursor: 'pointer', width: '15%' }}>
              <b>Product</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'products'} />
            </th>
            <th align="left" onClick={() => onSort('scheduled_start')} style={{ cursor: 'pointer', width: '12%' }}>
              <b>Scheduled Start</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'scheduled_start'} />
            </th>
            <th align="left" onClick={() => onSort('scheduled_end')} style={{ cursor: 'pointer', width: '12%' }}>
              <b>Scheduled End</b>
              <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'scheduled_end'} />
            </th>
            <th onClick={() => onSort('delta')} style={{ cursor: 'pointer', textAlign: 'right' }}>
              <b>Delta</b>
              <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'delta'} />
            </th>
            <th onClick={() => onSort('grand_total')} style={{ cursor: 'pointer', textAlign: 'right' }}>
              <b>Total</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'grand_total'} />
            </th>
            <th align="left" onClick={() => onSort('mpa_status')} style={{ cursor: 'pointer', width: '10%', textAlign: 'center' }}>
              <b>Status</b>
              <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'mpa_status'} />
            </th>
            <th onClick={() => onSort('notes')} style={{ cursor: 'pointer', width: '17%' }}>
              <b>Notes</b> <SortButton isAsc={sortKey.isAsc} isCurrent={sortKey.sortKey === 'notes'} />
            </th>

            <th align="left" style={{ width: '10%' }}>
              <b>Edit</b>
            </th>
            {/* {userPermissions.includes('delete_mpa') && <th align="left" style={{width:"10%"}}><b>Delete</b></th>} */}
          </tr>
        </thead>
        <tbody>
          {/* {(searchValue || fiscalFilter ?  */}
          {tableData.displayData.map((row: any) => (
            <tr key={row.id}>
              <td align="left">
                {row.legacy_mpa} {row.revision_number}
              </td>
              <td align="left">{row.fiscal}</td>
              <td align="left">{row.client_business_unit}</td>
              <td align="left">{row.products}</td>
              <td align="left">{format_date(row.scheduled_start)}</td>
              <td align="left">{format_date(row.scheduled_end)}</td>
              {/* <td align="left">{row.channel}</td> */}
              {/* <td align="left">{row.phase_number}</td> */}
              <td align="right">{formatNumberToCanadianDollars(row.delta)}</td>
              <td align="right">{formatNumberToCanadianDollars(row.grand_total)}</td>
              <td align="center">{row.mpa_status}</td>
              <td align="left">{row.notes}</td>
              <td>
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/map/mpa/edit/${row.id}`, '/map/mpa');
                  }}
                  icon="edit"
                  size="sm"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </DashboardTable>
      <PaginationToolbar>
        <RowsPerPageDropdown count={tableData.totalCount} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
        <TablePagination count={tableData.totalCount} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} />
      </PaginationToolbar>
    </>
  );
};

export default MpaDashboard;
