import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonToolbar } from 'reactstrap';
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import MpaSigningProcessModal from '../Common/MpaSigningProcessModal';

import { getMpasCsv } from '../../../State/Mpa/Actions/MpaActions';
import history from '../../../Utils/History';

interface IMpaToolbar {}

const MpaToolbar = ({}: IMpaToolbar) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(getUserPermissions);

  const exportMpaData = () => {
    dispatch(getMpasCsv());
  };

  return (
    <ButtonToolbar style={{ padding: '1.5rem 0' }}>
      {userPermissions.includes('add_mpa') && (
        <Button
          color="primary"
          onClick={() => {
            history.push('/map/mpa/add', '/map/mpa');
          }}
          className="mr-1">
          Add New MPA
        </Button>
      )}
      <Button color="primary" onClick={exportMpaData} className="mr-1">
        Export all MPA data to CSV
      </Button>

      <MpaSigningProcessModal />
    </ButtonToolbar>
  );
};

export default MpaToolbar;
